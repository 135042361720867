import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { active_project, vis_calculate_benefit, vis_deposite, vis_movein_price, vis_prepaidrent } from 'state/store.global'
import { getFocus, useAtom, useGet } from 'state/jotai'
import { useHousingBenefit } from 'components/HousingBenefit/HousingBenefit.hook'
import { _project } from "state/store.projects";
import { useBreak } from "hooks/useBreak";
import { Icon } from "assets/Icons_";

function Properties({ groups, indent,isRent }) {
  const setBenefit = useAtom(vis_calculate_benefit)[1]
  const output = useHousingBenefit()
  const isMobile = useBreak("md_dn");

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  // console.log(buttonsLabel,"labelll")
// console.log(groups,"pppppp")
const setMovePrice = useAtom(vis_movein_price)[1];
const setDepositePrice = useAtom(vis_deposite)[1];
const setPrePaidRent = useAtom(vis_prepaidrent)[1];

  return (
    <>
      {groups &&
        groups.map((group, i) => {
          const isAllValuePresent = group.data && group.data.every((data, i) => data.Value === "" || data.Value === null || data.Value === undefined || data.Value === "999.999" || data.Value === "999999" || data.Value === 0 || data.Value === "0")
          
          if(isAllValuePresent) return <></>

          return (
            <Box
              key={i}
              sx={{
                mb: 3,
                "&:last-child": {
                  mb: "0",
                },
              }}
            >
              <Box sx={{
                
                mb: "10px", marginLeft: isMobile ? "22px" : 0
                
              }} className="h4">
                {group.title}
                </Box>
              <div>
                {group.data &&
                  group.data.map((data, i) => {
                    const label = data?.PropertyID === 48
                    ? isRent
                      ? buttonsLabel?.filter(btn => btn?.UILabelProperty === "MoveInDateRent")[0]?.Label
                      : buttonsLabel?.filter(btn => btn?.UILabelProperty === "MoveInDateSales")[0]?.Label
                    : data.Label;
                    const value =
                      data.Value === true
                        ? buttonsLabel?.filter(btn => btn?.UILabelProperty === 'Ja')[0]?.Label
                        : data.Value === false
                          ? buttonsLabel?.filter(btn => btn?.UILabelProperty === 'Nej')[0]?.Label
                          : data.Value;
                    const postfix = data.Postfix ?? "";
                    // if(data?.PropertyID === 48) return<></>
                    if(data.PropertyID === 113 || data.PropertyID === 104 || data.PropertyID === 108  && !isRent) return <></>
                     // if value is one of them then we don't have to show it in the frontend
                     if(value === "" || value === null || value === undefined || value === "999.999" || value === "999999" || value === 0 || value === "0") return null


                    {/* if ( data.PropertyID !== 113 ) return ( */ }
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          px: indent ? 3 : 0,
                          py: 1.5,
                          borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
                          '&:last-child': {
                            borderBottom: 'none',
                          },
                        }}
                      >
                       <Box sx={{ display: "flex", gap: "2px" }}>
                       <div className="paragraph2-light" >{label}</div>
                       { data.PropertyID == 102 ? (
                              <Box
                                onClick={() => setMovePrice(true)}
                                sx={{
                                  color: "unset",
                                  cursor: "pointer",
                                  position: "relative",
                                  top: "0px",
                                  left: "2px",
                                }}
                              >
                                <Icon icon="info" color="#000" size="11" />
                              </Box>
                            ) : (
                              ""
                            )}
                            
                            { data.PropertyID == 106 ? (
                              <Box
                                onClick={() => setDepositePrice(true)}
                                sx={{
                                  color: "unset",
                                  cursor: "pointer",
                                  position: "relative",
                                  top: "1px",
                                  left: "2px",
                                }}
                              >
                                <Icon icon="info" color="#000" size="11" />
                              </Box>
                            ) : (
                              ""
                            )}
                            { data.PropertyID == 108 ? (
                              <Box
                                onClick={() => setPrePaidRent(true)}
                                sx={{
                                  color: "unset",
                                  cursor: "pointer",
                                  position: "relative",
                                  top: "0px",
                                  left: "1px",
                                }}
                              >
                                <Icon icon="info" color="#000" size="11" />
                              </Box>
                            ) : (
                              ""
                            )}
                            </Box>
                        {data.PropertyID !== 113 ? (
                          <div className="paragraph2-light">
                            {value}
                            {postfix}
                          </div>
                        ) : (
                          <>
                            {
                              output
                                ? <div onClick={() => setBenefit(true)} style={{ color: 'unset', cursor: 'pointer', textDecoration: 'underline' }}>{output}</div>
                                : 
                                <div
                                onClick={() => setBenefit(true)}
                                style={{ color: 'unset', cursor: 'pointer', textDecoration: 'underline', fontSize: "14px" }}
                              >
                                {buttonsLabel?.filter(btn => btn.UILabelProperty === 'Beregn')[0]?.Label}
                              </div>
                                // <div className="paragraph2-regular " onClick={() => setBenefit(true)} style={{ fontWeight: "600", color: 'unset', cursor: 'pointer', textDecoration: 'underline' }}>{buttonsLabel?.filter(btn => btn.UILabelProperty === 'Beregn')[0]?.Label}</div>
                            }
                          </>
                        )}
                      </Box>
                    )
                  })}
              </div>
            </Box>
          );
        })}
    </>
  );
}

Properties.propTypes = {
  groups: PropTypes.array.isRequired,
  indent: PropTypes.bool,
};

export default Properties;
