import { Box } from "@mui/material";
import React from "react";
import {
  Buttons,
  DrawerCTA,
  Gallery,
  Highlights,
  Price,
  TopDrawer,
  useActiveUnit,
} from ".";
import { useUnitHighlight } from "./hooks";
import OpenHouse from "./OpenHouse";
import { getFocus, useAtom, useGet } from "state/jotai";
import { get, label } from "components/Favorites/Favorites.Utilities";
// import { drawer_animation_done } from "state/store.global";
import {
  _drawer,
  active_project,
  drawer_animation_done,
} from "state/store.global";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import ReservationButton from "./ReservationButton";
import DropdownProperties from "./DropdownProperties";
import { useBreak } from "hooks/useBreak";
import { Scrollbar } from "react-scrollbars-custom";
import { useLocation } from "react-router-dom";
import { _bootstrap } from "state/store.bootstrap";
import { _project } from "state/store.projects";

function UnitDrawer() {
  const here = useLocation().pathname;
  const { pin, pid } = useGet(active_project);
  const unit = useActiveUnit();
  const highlights = useUnitHighlight(unit);
  const unitId = parseInt(unit.getId());
  const status = unit.getStatus();
  const address = unit.getAddress();
  const moveindate = unit.getMoveInDate();
  const townAndZipcode = unit.getTownZipCode();
  const isRent = unit.isRent();
  const price = unit.getPrice();
  const properties = unit.getProperties();
  const Pris = unit.getPris();

  const availablePris = unit?.data?.Data?.Pris_Tekst_DK?.Value === "";

  const filteredProperties = availablePris
    ? properties
    : properties.filter((property) => property?.order !== 20);

  const isDrawerAnimating = useAtom(drawer_animation_done)[0];
  const isMobile = useBreak("md_dn");
  const drawer = useAtom(_drawer)[0];
  const isDetail = drawer.detail;
  const thumbnails = isDetail ? unit.getThumb() : [];
  const images = isDetail ? unit.getImages() : [];
  const isSales = unit.isSales();

  
 

  const projectType = getFocus(_bootstrap, "type");

  const today = Boolean(moveindate) ? moveindate : false;

  // Specify Danish as the locale and customize options
  // const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const danishFormattedDate = Boolean(moveindate) ? moveindate : today;

  return (
    <Box
      sx={{
        width: "100%",
        height: isMobile ? "calc(100% - 73px)" : "calc(100% - 157px)",
        backgroundColor: "#ffffff",
        overflowY: "auto",
        scrollbarWidth: "none",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderLeft: `1px solid ${clr?.primary}`,
      }}
    >
      <Scrollbar
        trackYProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <span {...restProps} ref={elementRef} className="trackY" />;
          },
        }}
        contentProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return (
              <span
                {...restProps}
                ref={elementRef}
                style={{ height: "100%", display: "block" }}
                className="Content"
              />
            );
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Box sx={{ position: "relative", zIndex: 2 }}>
              <TopDrawer
                availableDate={danishFormattedDate}
                address={`${address}, ${townAndZipcode}`}
                statusText={status.label}
                statusId={status.id}
                backgroundColor={status.color}
                textColor="#ffffff"
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: "-4px",
                zIndex: 1,
                background: "white",
              }}
            >
              <Gallery
                unitId={unitId}
                images={images}
                thumbImg={thumbnails}
                UnitDrawer
                width={isMobile ? " 100%" : "375px"}
                height={isMobile ? " 100%" : "273px"}
              />
            </Box>
            <Buttons
              unitId={unitId}
              address={
                address || townAndZipcode ? `${address}, ${townAndZipcode}` : ""
              }
              rent={price}
              highlights={highlights}
            />

            <OpenHouse />

            <Box sx={{ mt: 3 }}>
              <Highlights items={highlights} />

              {/* <Box
                className="h3"
                sx={{
                  mt: { md: 3, xs: 2 },
                  textAlign: "center",
                }}
              >
                {" "}
                {`${address}, ${townAndZipcode}`}
              </Box> */}

              {price && (
                <Box
                  sx={{
                    mt: { md: 3, xs: 2 },
                    textAlign: "center",
                  }}
                >
                  <Price
                    label={
                      availablePris && isRent
                        ? `${get(unit.data, "LejelejlighedsText")}`
                        : isSales && availablePris
                        ? `${label(unit.data, "Kontantpris")}`
                        : ""
                    }
                    value={availablePris && price ? Pris : ""}
                  />
                </Box>
              )}
              <ReservationButton unit={unit} />
              <DropdownProperties groups={filteredProperties} isRent={isRent} />
            </Box>
          </div>
          <PoweredBy />
        </Box>
      </Scrollbar>
    </Box>
  );
}

export default UnitDrawer;
