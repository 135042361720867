import { Box, Grid, useMediaQuery } from "@mui/material";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { useBreak } from "hooks/useBreak";
import { useEffect, useRef, useState } from "react";
import { useGet, useSet } from "state/jotai";

const PropertyCard = ({
  units,
  createGroupArr,
  x,
  buttonsLabel,
  GroupNames,
  showDiff,
  handleScroll,
  propertyRef,
  isRent
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isDesktop = useBreak("md_up");
  let isValuePresent = {};

  const compareProperty = GroupNames?.map((gn) => {
    let floorplan = "";
    let idx = 0;
    let prisValue = "";
    let prisPost = "";
    let groupArr = [];
    let data = [];
    let unitsData = [];
    data = units.map((i, j) => {
      floorplan = i.Gallery?.Floorplan?.Url;
      idx = j < i.Gallery?.ListOfPhotos.length ? j : 0;
      prisValue = i.Data?.Husleje?.Value;
      prisPost = i.Data?.Husleje?.Postfix;
      groupArr = createGroupArr({ i, comparePropertyNames: x });
// console.log(groupArr,"arrr")


   
      if (groupArr[gn]?.length) {
        isValuePresent = {};
        unitsData = groupArr[gn]
          ?.map((item, index) => {
            const xy = units.map((sUnit, jUnit) => {
              const groupArrUnit = createGroupArr({
                i: sUnit,
                comparePropertyNames: x,
              });
              const a = groupArrUnit[gn]?.filter((gItem, index) => {
                if (gItem.Label === item.Label) {
                  if (
                    !(
                      gItem?.Value === "" ||
                      gItem?.Value === null ||
                      gItem?.Value === "0" ||
                      gItem?.Value === "999.999"
                    ) &&
                    !isValuePresent[item.Label]
                  ) {
                    isValuePresent[item.Label] = true;
                  }
                  return true;
                }

                return false;
              });
              return a?.length ? a[0].Value : [];
            });
            if ((item.PropertyID === 113 || item.PropertyID === 104 || item.PropertyID === 108) && !isRent) return <></>
            const value =
              item.Value === true
                ? buttonsLabel?.filter((btn) => btn.UILabelProperty === "Ja")[0]
                    ?.Label
                : item.Value === false
                ? buttonsLabel?.filter(
                    (btn) => btn.UILabelProperty === "Nej"
                  )[0]?.Label
                : item.Value;
            const label = item.Label;
            const postfix = item.Postfix;
            const IsDifferent = item?.IsDifferent;
            const ShowValue = item.ShowValue;
            return {
              label,
              postfix,
              value:
                value === "" ||
                value === null ||
                value === "0" ||
                value === "999.999"
                  ? isValuePresent[item.Label]
                    ? "-"
                    : ""
                  : value,
              // value: value,
              IsDifferent,
              ShowValue,
            };
          })
          .filter((d) => {
            return !(
              d.value === "" ||
              d.value === null ||
              d.value === "0" ||
              d.value === "999.999"
            );
          });
      }
      return {
        unitsData,
      };
    });
    return {
      label: gn,
      data,
      idx,
      prisValue,
      prisPost,
    };
  });
  return (
    <div
      style={{
        padding: !isDesktop ? "0 0 0 16px" : "0 0 0 48px",
        width: "100%",
      }}
    >
      <div
        ref={propertyRef}
        onScroll={(e) => {
          e.preventDefault();
          handleScroll(e, "normalSwiper");
        }}
        style={{ overflow: "auto", height: "100%", position: "relative" }}
      >
        {compareProperty?.map((g, i) => {
          const hideLabel = g?.data?.filter(
            (gItem) => gItem?.unitsData?.length
          );
          return (
            <div key={i}>
              {Boolean(hideLabel?.length) && (
                <div key={i}>
                  <div
                    style={{
                      width: `${
                        (!isDesktop ? 250 : 450) * units?.length +
                        4 * (units?.length - 1)
                      }px`,
                      background: clr?.primary,
                      color: clr?.primary_text,
                      height: "57px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: !i ? "6px 6px 0px 0px" : 0,
                    }}
                  >
                    {g?.label}
                  </div>
                  <div style={{ display: "flex" }}>
                    {g?.data.map((gi, j) => {
                      // const salesType  = i.Data.SalesTypeName.Value;
                      // const status     = i.Data.UnitStatusName.UnformattedValue;
                      // const floorplan  = i.Gallery?.Floorplan?.Url;
                      // const idx        = j < i.Gallery?.ListOfPhotos.length ? j : 0;
                      // const coverImage = floorplan
                      //   ? floorplan
                      //   :   i.Gallery?.ListOfPhotos[idx]?.Url || "";  // use random image from gallery if floorplan is missing
                      // const address   = i.Data.Adresse.Value;
                      // const prisValue = i.Data?.Husleje?.Value;
                      // const prisPost  = i.Data?.Husleje?.Postfix;
                      // const pris       = prisValue === '-' ? prisValue : `${prisValue} ${prisPost}`
                      // const pris =
                      //   parseFloat(prisValue) > 0
                      //     ? `${prisValue} ${prisPost}`
                      //     :   prisValue;
                      // const groupArr  = createGroupArr({ i, comparePropertyNames: x });
                      // const ColorCode = i.Data.UnitColor.Value;
                      // const projeknr  = `${
                      //   buttonsLabel?.filter(
                      //     (btn) => btn?.UILabelProperty === "Bolignr"
                      //   )[0]?.Label
                      // } ${i.Data?.Projeknr?.Value}`;
                      // const townAndZipcode =
                      //   i?.Data?.ZipcodeID?.Value + " " + i?.Data?.By?.Value;
                      // const pricePf = i?.Data?.SalesTypeLejelejlighed?.Value
                      //   ? i?.Data?.LejelejlighedsText?.Value
                      //   :   "Kontantpris";
                      return (
                        <div
                          key={i + j}
                          style={{
                            minWidth: !isDesktop ? "250px" : "450px",
                            maxWidth: !isDesktop ? "250px" : "450px",
                            marginRight:
                              j + 1 === g?.data?.length ? "0" : "4px",
                          }}
                        >
                          {gi?.unitsData?.length ? (
                            gi?.unitsData?.map((item, index) => {
                              const value =
                                item.value === true
                                  ? buttonsLabel?.filter(
                                      (btn) => btn.UILabelProperty === "Ja"
                                    )[0]?.Label
                                  : item.value === false
                                  ? buttonsLabel?.filter(
                                      (btn) => btn.UILabelProperty === "Nej"
                                    )[0]?.Label
                                  : item.value;
                              const label = item.label;
                              const postfix = item.postfix;
                              // if value is one of them then we don't have to show it in the frontend
                              if (
                                value === "" ||
                                value === null ||
                                value === undefined ||
                                value === "999.999" ||
                                value === "999999" ||
                                value === 0 ||
                                value === "0"
                              )
                                return null;

                              // if ( showDiff && !item.IsDifferent ) { return null }
                              if (item.ShowValue == false) {
                                return null;
                              }
                              // for getting  differnve value
                              if (showDiff && !item?.IsDifferent) {
                                return null;
                              }
                              return (
                                <Box
                                  key={i + j + index}
                                  sx={{
                                    borderRight: "1px solid rgba(0,0,0,0.05)",
                                  }}
                                >
                                  <Box>
                                    <Grid>
                                      <Grid item key={i} xs={4}>
                                        <Box
                                          sx={{
                                            py: 1.1,
                                            lineHeight: 1.5,
                                            backgroundColor: item?.IsDifferent
                                              ? `${clr?.footer_bgcolor}`
                                              : "#EFEFEF",
                                            px: 3,
                                            borderBottom: item?.IsDifferent
                                              ? "1px solid #fff"
                                              : "1px solid #0000001f",
                                            height: "62px",
                                          }}
                                        >
                                          <Box sx={{ fontWeight: "bold" }}>
                                            {label}
                                          </Box>
                                          <Box>
                                            {value}
                                            {postfix}
                                          </Box>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Box>
                              );
                            })
                          ) : (
                            <Box sx={{ height: "1px" }}></Box>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        })}
        <PoweredBy />
      </div>
    </div>
  );
};

export default PropertyCard;
